a {
    text-decoration: none;
    color: inherit;
}

/* ------------------------------ANNOUNCEMENT-------------------------- */
.home {
    /* background-color: #f9f3ee; */
}

.fourofour {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.announcementContainer {
    background-color: #ccb757;
    padding-left: 10%;
    font-size: 20px;
}

.navbar {
    /* position: fixed; */
    width: 100%;
    z-index: 5;
    background-color: white;
}

.mobile-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: 0 1px 10px;
}

.nav-copyright {
    font-size: .7rem;
    text-align: center;
}

.collapse {
    height: 0px;
    overflow: hidden;
    /* transition: all 0.5s ease; */
}

.logoContainer {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoContainer span {
    font-size: 1.5rem;
    font-weight: 700;
}

.logo {
    cursor: pointer;
    height: 100%;
    transition: all 0.5s ease;
}

.logo:hover {
    transform: scale(1.1);
}

.menu {
    background-color: rgb(252, 238, 221);
    position: fixed;
    text-align: center;
    right: 0;
    height: auto;
    width: 50%;
    height: 100vh;
    z-index: 50;
    overflow: hidden;
    font-weight: 500;
    box-shadow: 1px 1px 10px;
    transition: all 0.5s ease-out;
}

.whatsapp {
    position: fixed;
    bottom: 0;
    margin-bottom: 5rem;
    right: 0;
    margin-right: 1rem;
    z-index: 7000;
}

.active-menu {
    background-color: #ccb757;
}

.nav-name {
    margin-top: 2rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.menu :last-child {
    margin-bottom: 10px;
}

.hamburger {
    transition: all 0.5s ease;
    cursor: pointer;
}

.hamburger-active {
    border: 2px solid #ccb757;
}

.hamburger>* {
    font-size: 30px;
}

.menu hr {
    margin-bottom: 3rem;
}

.menuItem {
    padding: 1rem 0;
    margin-top: 1rem;
    cursor: pointer;
    transition: all 1s ease;
    font-weight: 700;
}

.menuItem:hover {
    transform: scale(1.1);
}

.user {
    margin: 20px;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.userBtn {
    margin: 0 10px;
    padding: 10px;
    background-color: #ccb757;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.5s ease;
}

.userBtn:hover {
    transform: scale(1.1);
}

.userBtn:active {
    transform: scale(0.8);
}

.desktop-navbar {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: auto;
    max-width: 80%;
}

.dm-logoContainer {
    flex: 1;
    height: 70px;
    padding-left: 10px;
}

.desktop-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 3;
    max-width: 700px;
    font-weight: 700;
}

.dn-user {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: right;
}

.dmBtn {
    margin: 0 5px;
    padding: 1rem;
    border-radius: 5px;
    background-color: white;
    font-size: 1.1rem;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.5s ease;
}

.dmBtn:hover {
    transform: scale(1.1);
    background-color: #ccb757;
}

.dm-item {
    cursor: pointer;
    transition: all .5s ease;
    font-size: 1.3rem;
}

.dm-item:hover {
    transform: scale(1.1);
    color: #ccb757;
}

/* ---------------------------------SLIDER------------------------------- */
.slide-container {
    display: flex;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.slide {
    width: 100vw;
    transition: all 0.5s ease;
}

.slide-wrapper {
    display: flex;
    transition: all 0.5s ease;
}

.slide-text {
    margin-top: 50px;
    text-align: center;
}

.hero-text {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 20px;
}

.hero-text span {
    /* color: #e4c022; */
    color: #307098;
}

.hero-subtext {
    font-size: 20px;
    text-align: center;
    width: 95%;
    margin: auto;
    font-weight: 500;
    margin-bottom: 20px;
}

.slide-btn {
    text-align: center;
    transition: all 0.5s ease;
}

.slide-btn:active {
    transform: scale(0.9);
}

.slide-btn button {
    background-color: #ccb757;
    border: 1px solid black;
    padding: 10px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.5s ease;
    margin-bottom: 20px;
    font-size: 20px;
}

.slide-btn button:hover {
    transform: scale(1.1);
}

.slideImg-container {
    width: 100%;
}

.slide-img {
    width: 100%;
}

.arrow {
    width: 50px;
    height: 50px;
    background-color: #fff7f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    opacity: 0.5;
}

.left {
    z-index: 2;
}

.right {
    right: .1px;
    z-index: 2;
}


/* -----------------------------ABOUT SECTION---------------------- */
.section {
    padding-top: 20px;
    line-height: 1.5;
}

.section-hero-text {
    text-align: center;
    width: 30%;
    padding: 10px;
    font-size: 1.3rem;
    font-weight: 500;
    background-color: #ccb757;
    font-weight: 700;
}

.section-subtext-container {
    background-color: #f7ede5;
    padding: 10px;
}

.about-subtext {
    margin-bottom: 30px;
}


/*--------------------------------------- SERVICES -----------------------------------------*/

.services-subtext-container {
    background-color: white;
    padding: 10px;
}

.service-img-container {
    margin-top: 10px;
}

.service-img {
    padding: 0 5px;
}

.service-img img {
    width: 100%;
    height: 100%;
}

.service-img-subtext {
    margin: 5px 0 30px 0;
    text-align: center;
    font-size: 1.1rem;
}


/* -----------------------------------------PARTNERS--------------------------------------- */
.partners-subtext-container {
    background-color: #f7ede5;
    padding: 10px;
}

.partners-img-container-one {
    background-color: #fdf8f9;
}

.partners-img-container-two {
    background-color: white;
}

.partners-img {
    padding-top: 20px;
    height: 50px;
    text-align: center;
}

.partners-img img {
    height: 80%;
}

.first {
    height: 433px;
    background-color: #f7ede5;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: right;
    line-height: 14px;
    font-size: 12px;
    font-feature-settings: normal;
    text-size-adjust: 100%;
    padding: 0px;
    margin: 0px;
    width: 100%;
}

.second {
    height: 413px;
    padding: 0px;
    margin: 0px;
    width: 100%;
}

.third {
    color: #626B7F;
    line-height: 14px;
    font-weight: 400;
    font-size: 11px;
    box-sizing: border-box;
    padding: 2px 6px;
    width: 100%;
    font-family: Verdana, Tahoma, Arial, sans-serif;
}

.fourth {
    border: 0;
    margin: 0;
    padding: 0;
}

.fifth {
    font-weight: 500;
    color: #626B7F;
    text-decoration: none;
    font-size: 11px
}

.cs-container {}

.crypto-slider {
    position: fixed;
    width: 100%;
    bottom: 0;

}

/* ----------------------------------------------RATINGS--------------------------------------------- */
.ratings {
    /* display: flex;
    justify-content: center;
    /* position: relative; */
    /* height: auto;
    color: white;  */
    padding: 20px 0 40px 0;
    background: rgba(0, 0, 0, 0.7) url(../assets/rating.jpg);
    background-size: cover;
    background-blend-mode: darken;
    color: white;
    text-align: center;
}

.ratings-container {
    /* position: absolute; */
    max-height: 650px;
}

.ratings-background {
    /* width: 100%; */
    max-width: 650px;
}

.overlay {
    height: 100%;
    width: 100vw;
    z-index: 2;
    /* position: absolute; */
    background-color: black;
    opacity: 0.8;
}

.rating-text {
    /* position: absolute; */
    width: 100%;
    text-align: center;
    margin: auto;
    /* padding: 0 10px; */
    z-index: 3;
    max-width: 900px;
}

.rtHeader {
    margin-top: 50px;
    font-size: 2rem;
    font-weight: 700;
}

.rtHeader span {
    display: block;
    color: #ccb757;
}

.rtSubtext {
    margin-top: 20px;
    line-height: 1.5;
    padding: 0 15px;
}

.rating {
    margin-top: 30px;
}

.number {
    font-size: 2rem;
    font-weight: 700;
}


/* ---------------------------------------------CRYPTO------------------------------------------------ */
.crypto {
    position: relative;
}

/* ---------------------------------------------BUY COINS--------------------------------------------- */
.buy-container {
    color: black;
    background-color: #f7ede5;
}

.bc-header {
    font-size: 2rem;
    text-align: center;
    font-weight: 700;
}

.coin {
    text-align: center;
    padding-bottom: 1rem;
    margin-bottom: 30px;
}

.binance-logo {
    margin-top: 20px;
}

.coin-btn {
    margin-top: 10px;
    transition: all 0.5s ease;
}

.coin-btn:active {
    margin-top: 10px;
    transform: scale(0.9);
}

.coin-btn button {
    border-radius: 5px;
    color: white;
    padding: 10px;
    border: none;
    background-color: #307098;
}

.coin img {
    margin: auto;
    max-width: 50%;
}

.bitfinex-logo img {
    max-width: 20%;
}

.coinbase-logo img {
    width: 40%;
}

.paxful-logo img {
    width: 30%;
}

.luno-logo img {
    width: 20%;
}


/* -------------------------------------------INVEST--------------------------------------------- */
.invest-header {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    background-color: #ccb757;
    width: 50%;
    padding: 1rem;
}

.invest-subtext {
    text-align: center;
    padding: 0 1rem;
    line-height: 1.5;
}

/* -----------------------------------------------------PARTNERSHIP----------------------------------------------------- */
.partnership {
    background-color: #f7ede5;
    margin-top: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
}

.partnership-header {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 1rem;
}

.ps-text {
    line-height: 1.5;
    margin-bottom: 1rem;
    padding: 0 1rem;
    text-align: center;
}

.ps-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: 0 0;
    margin-bottom: 1rem;
}


/* -------------------------------------------------------OFFICE-------------------------------------------------------------- */
.office-subtext {
    text-align: center;
    padding: 0 1rem;
    margin-bottom: 1rem;
    line-height: 1.5;
}

.office-item {
    width: 90%;
    margin: auto;
    background-color: #fdf8f9;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.office-item img {
    width: 100%;
    margin-bottom: 1REM;
}

.oi-header {
    margin-bottom: 1rem;
    padding: 0 1rem;
    font-size: 1.1rem;
    font-weight: 500;
}

.oi-text {
    line-height: 1.5;
    padding: 0 1rem;
}


/* -----------------------------------------------------CONTACT---------------------------------------------- */
.contact-header {
    background-color: #ccb757;
    width: 40%;
    font-weight: 700;
    padding: 1rem;
    font-size: 1.5rem;
}

.contact-container {
    background-color: #f7ede5;
    padding: 2rem 1rem;
    display: flex;
    justify-content: space-between;
}

.c-name {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 500;
}

.c-name p {
    margin-bottom: 1rem;
    text-align: center;
}

.c-info {
    margin-left: 1rem;
    width: 70%;
    display: flex;
    flex-direction: column;
}

.c-info p {
    margin-bottom: 1rem;
}


/* ----------------------------------------------------------------FOOTER------------------------------------------------ */
.footer {
    background-color: #1D2330;
    color: white;
    font-size: .7rem;
    padding-bottom: 70px;
    padding-top: 3rem;
}

.footer p {
    line-height: 2;
    padding: 0 1rem;
}

.social-media-container {
    text-align: center;
    margin-bottom: 2rem;
}

.copyright {
    text-align: center;
}

.dashboard {
    padding: 0 .5rem;
}

.username {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.username span {
    font-size: 1.8rem;
    color: #ccb757;
    font-weight: 700;
}

.welcome-msg {
    margin-bottom: 1rem;
}

.account-info-container {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.account-info {
    background-color: #f7ede5;
    padding: 1rem;
    margin-bottom: 2rem;
    width: 40%;
    border-radius: 5px;
}

.main-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.amount {
    margin-bottom: .5rem;
    font-size: 1.2rem;
    color: #ccb757;
    font-weight: 700;
}

.subtext {
    color: #626B7F;
    font-size: .8rem;
}

.account-link {
    margin-top: 1rem;
    text-decoration: underline;
    transition: all 0.5s ease;
}

.account-link:active {
    transform: scale(0.9);
}

.current-plan-container {
    padding: 1rem;
    background-color: #f7ede5;
    width: 100%;
    margin-bottom: 2rem;
}

.current-plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cp-info p {
    margin-bottom: 1rem;
}

.cp-value p {
    margin-bottom: 1rem;
    font-weight: 700;
    text-align: right;
}



/* ----------------------------------------------FUND-------------------------------------- */
.amount {
    color: black;
    margin-bottom: .5rem;
}

.amount-input {
    margin-bottom: 2rem;
    padding: .5rem;
    font-size: 1rem;
}

.amount input {
    margin-top: 1rem;
    padding: .5rem;
    font-size: 1rem;
    border: 1px solid gray;
}

.fund {
    padding: 1rem;
}

.fund-header {
    padding: 1rem;
    font-size: 1.8rem;
    font-weight: 700;
}

.select-header {
    padding: 1rem;
    font-size: 1.3rem;
}

.coins-container {
    background-color: #f7ede5;
    padding: 2.5rem 1rem;
}

.coin-address {
    border: none;
    padding: .5rem;
    font-size: 1rem;
}

.fund-coin {
    padding: 1rem;
    background-color: white;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
}

.coin-active {
    background-color: #ccb757;
}

.fund-coin img {
    width: 10%;
    padding-right: 1rem;
}

.copy {
    padding: .5rem;
    transition: all 0.5s ease;
}

.btn {
    transition: all 0.5s ease;
}

.btn:active {
    transform: scale(0.9);
}

.copied {
    color: green;
}

.upload-sub {
    margin-top: 1rem;
}

.upload {
    font-size: 1rem;
    margin-top: .5rem;
    background-color: white;
    padding: .5rem;
}

.fund-btn {
    background-color: #ccb757;
    border: none;
    padding: 1rem;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 3rem;
}

/* --------------------------------------------------------CONFIRM-PASSWORD---------------------------------------------------- */
.confirm-password {
    padding-top: 6rem;
}

.cp-container {
    background-color: #1D2330;
    padding: 2rem;
    width: 80%;
    margin: auto;
    color: white;
    line-height: 1.5;
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.pass {
    margin-top: 2rem;
    text-align: left;
}

.pass span {
    color: red;
}

.cp-container input {
    margin-top: 1rem;
    padding: .5rem;
    font-size: 1rem;
}

.cp-container button {
    display: block;
    margin-top: 1rem;
    padding: .5rem;
    font-size: 1rem;
    letter-spacing: .1rem;
    font-weight: 500;
    background-color: #ccb757;
}

/* -----------------------------------------------------------REQUEST WITHDRAWAL--------------------------------------------------- */
.request-withdrawal {}

.request-withdrawal p {
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
}

.rw-coin {
    width: 100%;
    padding: 1rem;
    background-color: #f7ede5;
    width: 85%;
    margin: auto;
    margin-bottom: 2rem;
}

.rw-coin button {
    padding: .5rem;
    background-color: #ccb757;
    margin-top: 1rem;
}

.rw-coin-name img {
    width: 10%;
    margin-right: 1rem;
}

.rw-coin-name p {
    padding: 0;
}

.rw-coin-name {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.rw-desc {
    display: flex;
    justify-content: space-between;
}

.rw-desc p {
    padding: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 300;
}

.rw-desc-value {
    text-align: right;
}

.withdraw-input {
    display: block;
    font-size: 1rem;
}

.withdraw-text {
    font-size: 1rem !important;
    font-weight: 500 !important;
    padding: 0 !important;
    margin-top: .3rem;
    margin-bottom: 1rem;
}

.wd-error {
    padding: 0 !important;
    font-size: 1rem !important;
    color: red;
}

.wd-success {
    color: green;
    padding: 0 !important;
    font-size: 1rem !important;
}

.wd-col {
    display: none !important;
}

/* ----------------------------------------------------------COMPLETE-WITHDRAWAL---------------------------------------------- */
.complete-withdrawal {
    padding-top: 6rem;
}

.cw-container {
    padding: 1rem;
    background-color: #f7ede5;
    width: 85%;
    margin: auto;
}

.cw-header {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    padding: 0 1rem;
    padding-top: 1rem;
}

.chosen-coin {
    margin-top: .5rem;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.cw-amount {
    margin-top: 1rem;
    font-weight: bolder;
}

.cw-amount span {
    color: red;
}

.cw-amount input {
    padding: .5rem;
    font-size: 1rem;
    margin-top: 1rem;
}

.cw-amount button {
    margin-top: 2rem;
    display: block;
    padding: .5rem;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: .1rem;
    background-color: #ccb757;
    border: none;
}

/* --------------------------------------------------------------SUBSCRIBE------------------------------------------------------- */
.subscribe {
    padding-top: 1rem;
}

.sub-header {
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
}

.plan-container {
    background-color: #f7ede5;
    padding: 1rem;
    width: 85%;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
}

.plan-name {
    font-size: 1.3rem;
    font-weight: 500;
}

.plan-price {
    font-size: 3rem;
    margin-top: 1rem;
    text-align: center;
}

.plan-price span {
    font-size: 1.8rem;
}

.plan-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2em;
}

.pd-value {
    text-align: right;
    font-weight: 700;
}

.pd-desc p {
    margin-bottom: 1rem;
}

.pd-value p {
    margin-bottom: 1rem;
}

.buy-plan {
    margin-top: 1rem;
}

.failed {
    color: red;
}

.succeed {
    color: green;
}

.buy-plan p {
    font-size: 1.1rem;
    margin-bottom: .5rem;
}

.buy-plan input {
    padding: .5rem;
    font-size: 1rem;
}

.buy-plan button {
    display: block;
    padding: .5rem;
    margin-top: 1rem;
    background-color: #ccb757;
    border: none;
    font-size: 1rem;
    font-weight: 700;
}

/* --------------------------------------------------------SUPPORT---------------------------------------------------- */
.support {}

.support-container {
    padding: 1rem;
    background-color: #f7ede5;
    margin-bottom: 3rem;
    width: 85%;
    margin: auto;
    border-radius: 10px;
}

.support-header {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: auto;
    padding: 1rem;
}

.support-container a {
    text-decoration: none;
}

.support-text {
    font-size: 1.2rem;
}

.message {
    margin-top: 2rem;
}

.message span {
    color: red;
}

.support-container textarea {
    padding: .5rem;
    height: 10rem;
    width: 90%;
    border: none;
    margin-top: 1rem;
}

.help-text {
    color: green;
}

.support-container button {
    font-weight: 700;
    display: block;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1rem;
    letter-spacing: .1rem;
    background-color: #ccb757;
    border: none;
}

/* ----------------------------------------------------------------------------LOGIN--------------------------------------------------------- */
.login {
    background: rgba(0, 0, 0, 0.8) url(../assets/rating.jpg);
    background-size: cover;
    background-blend-mode: darken;
    width: 100vw;
    height: 100vh;
    display: flex;

    justify-content: center;
    align-items: center;
}

.login-container {
    background-color: #f7ede5;
    width: 70%;
    padding: 1rem;
    /* height: 50%; */
}

.login-container input {
    font-size: 1rem;
    padding: .5rem;
    margin-top: .5rem;
}

.login-errors p {
    margin-bottom: 1rem;
    display: none;
    color: red;
}

.password span{
    vertical-align: middle;
    margin-left: .5rem;
}

.login-container button {
    display: block;
    margin-top: 1.5rem;
    padding: .8rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    border: none;
    background-color: #ccb757;
    margin-bottom: 1.5rem;
    transition: all 0.5s ease;
}

.login-container button:active {
    transform: scale(0.9);
}

.login-signin {
    font-weight: 700;
    transition: all 0.5s ease;
}

.login-signin:active {
    transform: scale(0.9);
}

.copyright-footer {
    text-align: center;
    padding-top: 1.5rem;
    line-height: 1;
    font-size: 0.7rem;
}

.login-header {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.signUp-errors p {
    margin-bottom: 1px;
    line-height: 1.5;
    color: red;
    display: none;
}

.show-error {
    display: block !important;
}

.login-username {
    font-size: 1.2rem;
}

.login-pass {
    font-size: 1.2rem;
}

.lu-value {
    margin-bottom: 1.5rem;
}

.lp-value {
    margin-bottom: 1.5rem;
}

.login-copyright{
    margin-top: 3rem;
    text-align: center;
}

/* ------------------------------------------------------------FAQs----------------------------------------------------------------- */
.faq {
    padding: 1rem;
    line-height: 1.5;
}

.faq-header {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.faq-header span {
    color: #ccb757;
}

.faq-sub {
    margin-bottom: 1.5rem;
}

.question {
    margin-bottom: 2rem;
}

.q-question {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
}

.q-question span {
    color: #ccb757;
}

.question hr {
    border: 1px solid #ccb757;
}

.q-answer {
    display: none;
}

.show-answer {
    display: block;
}

/* ------------------------------------------------------------------HISTORY------------------------------------------------ */
.history {
    padding: 1rem;
}

.history-header {
    font-size: 1.5rem;
    font-weight: 700;
}

.history-menu {
    margin-top: 1.5rem;
    background-color: #fff7f7;
    display: flex;
    font-weight: 500;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
}

.history-details {}

.history-details p {
    text-align: center;
    margin-top: 1rem;
}

.log-sign-container button {
    padding: 1rem;
    margin: 1rem;
    background-color: #ccb757;
    font-size: 1rem;
    font-weight: 700;
}

.success {
    background-color: #eaeef2;
    line-height: 1.5;
    padding: 2rem;
}

.success img {
    width: 100%;
}

.success-text {
    margin-top: -3rem;
    text-align: center;
}



















































@media screen and (min-width: 900px) {
    .logoContainer {
        height: 70px;
    }

    .logoContainer span {
        font-size: 50px;
        font-weight: 700;
    }

    .section {
        width: 90%;
        margin: auto;
        font-size: 1.5rem;
    }

    .section-hero-text {
        font-size: 2rem;
    }

    .slide-text {
        margin-top: 5rem;
    }

    .hero-text {
        font-size: 5rem;
    }

    .hero-subtext {
        font-size: 2rem;
        width: 70%;
        margin-top: 3rem;
        line-height: 1.5;
    }

    .slide-btn {
        margin: 2rem 0;
    }

    .slide-btn button {
        font-size: 1.5rem;
        padding: 1rem;
    }

    .section-subtext-container {
        padding: 2rem 4rem;
    }

    .service-img-subtext {
        font-size: 1.5rem;
    }

    .service-gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .service-img-container {
        width: 50%;
    }
}

@media screen and (min-width: 1500px) {
    .desktop-navbar {
        display: flex;
    }

    .mobile-navbar {
        display: none;
    }

    .section {
        line-height: 2;
    }

    .section-hero-text {
        width: 15%;
    }

    .section-subtext-container {
        display: flex;
        justify-content: space-between;
    }

    .about-subtext {
        width: 48%;
        line-height: 2;
    }

    .partners-gallery {
        display: flex;
        justify-content: space-between;
        padding-top: 2rem;
    }

    .partners-gallery div {
        padding: 1.5rem;
    }
}